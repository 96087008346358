//
//
//
//
//
//
//
//

import page from '~/mixins/page'

export default {
  mixins: [page],
  mounted() {
    if (this.res.entry?.appearance?.value === 'red') {
      document.body.classList.remove('dark')
      document.body.classList.add('red')
    } else if (this.res.entry?.appearance?.value === 'dark') {
      document.body.classList.remove('red')
      document.body.classList.add('dark')
    } else {
      document.body.classList.remove('red')
      document.body.classList.remove('dark')
    }
  },
}
